import { ApiBase } from '@/common/data/ApiBase';
import { ApiError } from '@/common/data/ApiError';
import { ApiHeaders } from '@/common/data/ApiHeaders';
import { ApiRequestConfig } from '@/common/data/ApiRequestConfig';
import { isServer } from '@/common/utils/NextFunctions';

import { AdminCache } from './AdminCache';

const isAuthorizationError = (response: Response) => response.status === 401;

const adminResponseHandler = (
  response: Response,
  innerHandler?: (res) => any
) => {
  //shouldn't ever be isAuthorizationError as errors are handled in the error handler
  if (!isAuthorizationError(response)) {
    return innerHandler ? innerHandler(response) : undefined;
  }
  if (window.location.href.includes('portal/auth/login')) return;
  window.location.href = `/portal/auth/login?redirectUrl=${window.location.href}`;
};

const adminErrorHandler = async (
  response: Response,
  innerHandler?: (res) => any
) => {
  if (!isAuthorizationError(response)) {
    if (innerHandler) return innerHandler(response);
    throw await ApiError.create(response);
  }
  if (window.location.href.includes('portal/auth/login')) return;
  window.location.href = `/portal/auth/login?redirectUrl=${window.location.href}`;
};

const getAdminHeaders = (): HeadersInit => {
  const headers = {} as HeadersInit;

  const companyId = AdminCache.companyId;
  if (companyId) {
    headers[ApiHeaders.CompanyId] = companyId.toString();
  }

  if (isServer()) {
    headers[ApiHeaders.Ssr] = true;
  }

  return headers;
};

const buildConfig = (config: ApiRequestConfig = {}): ApiRequestConfig => {
  return {
    ...config,
    headers: {
      ...getAdminHeaders(),
      ...config.headers
    },
    responseHandler: (res) => adminResponseHandler(res, config.responseHandler),
    errorHandlerAsync: (res) => adminErrorHandler(res, config.errorHandlerAsync)
  };
};

/**
 * This class should not be used directly unless for testing.
 *
 * Use "AdminLiveApi" or "AdminHubsApi".
 */
export class AdminApi extends ApiBase {
  public constructor(basePath: string) {
    super(basePath, buildConfig);
  }
}

export const AdminLiveApi = new AdminApi('api/v1/live-admin');
export const AdminHubsApi = new AdminApi('api/v1/hubs-admin');
